'use client'

import { createContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../Components/Loader';

export const AppContext = createContext({
    notify: () => null,
    loader: false,
    setLoader: () => null,
});

export const AppProvider = (props) => {
    const [loader, setLoader] = useState(false);

    const toastOptions = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored'
    };

    const provider = {
        notify: (_message, _type = 'success') => {
            if (_type === 'success') toast.success(_message, toastOptions);
            else if (_type === 'error') toast.error(_message, toastOptions);
            else if (_type === 'warn') toast.warn(_message, toastOptions);
        },
        loader,
        setLoader,
    };

    return (
        <AppContext.Provider value={provider}>
            <ToastContainer />
            {props.children}
            {loader && <Loader />}
        </AppContext.Provider>
    );
};
