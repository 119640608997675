import axios from "../axios";

// API for get countries
export const GetCountries = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`master-data`)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// API for get states
export const GetStates = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`master-data/states`)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// API for get districts
export const GetDistricts = (stateId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`master-data/state/${stateId}/districts`)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// API for get taluaks
export const GetTalukas = (districtId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`master-data/district/${districtId}/talukas`)
        .then((res) => {
          if (res.status === 200) {
            let result = res?.data;
            resolve(result);
          } else {
            reject(res?.data?.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};

// API for get village
export const GetVillage = (talukaId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`master-data/taluka/${talukaId}/villages`)
        .then((res) => {
          if (res.status === 200) {
            let result = res?.data;
            resolve(result);
          } else {
            reject(res?.data?.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};

// API for get districts
export const GetAllDistricts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`master-data/districts`)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// API for get districts
export const GetCasteData = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/master-data/category/${id}/caste`)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};