import axios from '../axios';

// API for get user details
export const GetUserData = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`user`, payload)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// API for add user details
export const AddUserData = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`user`, payload)
        .then((res) => {
          if (res.status === 201) {
            let result = res?.data;
            resolve(result);
          } else {
            reject(res?.data?.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  export const UpdateUserData = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`user`, payload)
        .then((res) => {
          if (res.status === 200) {
            let result = res?.data;
            resolve(result);
          } else {
            reject(res?.data?.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
