import axios from '../axios';

// API for get user courses
export const GetUserCourses = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/course`)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data;
          resolve(result);
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
