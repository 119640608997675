import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styles from '../../styles/header.module.scss';

const Header = forwardRef((props, ref) => {
  return (
    <>
      <header className="py-2 py-md-3" ref={ref}>
        <Container className="d-flex align-items-center gap-3 flex-lg-row position-relative">
          <img src={`${process.env.PUBLIC_URL}/assets/images/pillars_of_ashoka.png`} alt="Logo" className={styles.cet_logo_pillar} />  
          <div className={`header_cet_text_logo fw-semibold text-primary align-items-center lh-sm d-flex  gap-3 text-left flex-fill ${styles.cet_text_logo}`}>
          <Link to={'/'} className={`${styles.cet_logo_wrap}`}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/cet.png`} alt="Logo" className={styles.cet_logo} />
          </Link>
          <div className='right_text'>
            <div className='logo_heading'>GOVERNMENT OF MAHARASHTRA</div>
            <div className='slogan_title'>State Common Entrance Test Cell</div>
            </div>
          </div>
          <div className='right_logo'>
            <img src={`${process.env.PUBLIC_URL}/assets/images/Seal_of_Maharashtra.svg.png`} alt="Logo" className={styles.yellow_logo} />
          </div>
        </Container>
      </header>
    </>
  );
});

export default Header;
