import axios from 'axios';
import keycloakService from "../modules/index"; 
import env from "react-dotenv";

const api = axios.create({
  baseURL: env.REACT_APP_API_URL,
});

// request interceptor
api.interceptors.request.use(
  async (config) => {
    let auth_token = keycloakService.getToken();
    const shouldAddAccessToken =
      !config.headers['Authorization'] && !!auth_token;
    if (shouldAddAccessToken) {
      config.headers['Authorization'] = `Bearer ${auth_token}`;
    } 
    return config;

  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await keycloakService.refreshAccessToken(10);
        const accessToken = keycloakService.getToken();
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return api(originalRequest);
      } catch (error) {
        keycloakService.doLogout()
        console.log('Token refresh failed:');
      }
    }
    return Promise.reject(error.response);
  }
);
export default api;
