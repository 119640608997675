import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import styles from '../../styles/footer.module.scss';

const Footer = forwardRef((props, ref) => {
  const socialList = [
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/youtube.svg`,
      link: '/',
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/images/icons/facebook.svg`,
      link: '/',
    },
  ];
  return (
    <>
      <footer className="bg-lighter" ref={ref}>
        <Container>
          <div className="py-2 py-md-3 py-lg-4 border-bottom border-light text-center">
            <span className="d-block fs-7 fw-semibold text-primary mb-2">
              Office Address
            </span>
            <p className="fw-semibold">
              8th Floor, New Excelsior Building, A. K. Nayak Marg, Fort, Mumbai
              400001
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 py-3 py-md-4 text-center text-md-start">
            <p className="text-gray fs-8 mb-0">
              Copyright © State CET Cell,Maharashtra State, Mumbai. All rights
              reserved.(W2), 2024.
            </p>
            <div className="d-flex align-items-center gap-3">
              <span className="text-gray fs-8">Follow us on:</span>
              <ul className={`${styles.cet_social_list}`}>
                {socialList?.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link} target="_blank">
                      <ReactSVG src={item.icon} />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
});

export default Footer;
