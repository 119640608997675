module.exports = [
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/student_registration.svg`,
    title: "Registration",
    linkText: "Register Now",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MHT-CET 2024",
                linkUrl:
                  "https://mhtcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT-CET 2024",
                linkUrl:
                  "https://bhmctcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_bhmct_cet",
              },
              {
                title: "MAH-B.Design-CET-2024",
                linkUrl: "https://bdesign2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_bdesign_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2024",
                linkUrl: "https://bbabcacet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH-M.Planning Integrated CET-2024",
                linkUrl: "https://mplanningcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH-M.HMCT Integrated CET-2024",
                linkUrl: "https://mhmctintegratedcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_mhmctintegrated_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2024 (Additional Attempt)",
                linkUrl: "https://additional-bbabcacet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-MBA/MMS-CET 2024",
                linkUrl:
                  "https://mbacet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_mba_mms_cet",
              },
              {
                title: "MAH-M.Arch-CET 2024",
                linkUrl:
                  "https://marchcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2024",
                linkUrl:
                  "https://mhmctcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_mhmct_cet",
              },
              {
                title: "MAH-MCA-CET 2024",
                linkUrl:
                "https://mcacet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_mca_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
              {
                title: "MHT-CET-2024",
                linkUrl:
                  "https://mhtcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_cet_agriculture",
              },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MAH-LLB (5-Years) CET-2024",
                linkUrl:
                  "https://llb5cet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_llb_5years_cet",
              },
              {
                title: "MAH-B.Ed (General & Special) & B.Ed ELCT-CET-2024",
                linkUrl: "https://bedcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.P.Ed.-CET-2024",
                linkUrl: "https://bpedcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_bped_cet",
              },
              {
                title: "MAH-L.L.B.3 Yrs. CET-2024",
                linkUrl: "https://llb3cet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_llb_3years_cet",
              },
              {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2024",
                linkUrl: "https://babscbed2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_ba_bsc_bed_4years",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2024",
                linkUrl:
                  "https://bedmedcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                  linkText: "",
                  alternativeText: "Registration Closed.",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "M.Ed.- CET 2024",
                linkUrl: "https://medcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                  alternativeText: "Registration Closed.",
                enum: "mah_med_cet",
              },
              {
                title: "M.P.Ed. - CET 2024",
                linkText: "",
                alternativeText: "Registration Closed.",
                linkUrl: "https://mpedcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MH-Nursing CET 2024 (For B.Sc Nursing, ANM & GNM courses)",
                linkUrl: "https://bnursingcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "bsc_nursing_cet",
              },
              {
            	title: "MH-DPN/PHN-CET-2024",
            	linkUrl: "https://dpn.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
            	linkText: "",
            	alternativeText: "Registration Closed.",
            	enum: "mah_dpnphn_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title:
                  "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2024",
                  linkUrl: "https://pgcet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                  linkText: "Register Now",
                  alternativeText: "",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
        ],
      },
      {
        title: "Fine Art",
        types: [
          {
            type: "",
            linkUrls: [
              {
                title: "MAH-AAC CET",
                linkUrl: "https://mahaaccet2024.mahacet.org/RegistrationModule/frmInstructionsBeforeRegistration",
                linkText: "",
                alternativeText: "Registration Closed.",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/edit_profile.svg`,
    title: "Profile",
    linkText: "Edit Profile",
    editModal: true,
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/support-ticket.svg`,
    title: "Help Desk",
    linkText: "Create or Track Issues",
    linkUrl: "https://helpdesk.maharashtracet.org/login.php?do=ext&bk=oauth2.user.p1i2",
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/hall_ticket.svg`,
    title: "Hall Ticket or Admit Card",
    linkText: "Get Hall Ticket",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MHT-CET (PCB) 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-PCB-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCM) 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-PCM-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT-CET 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-B-HMCT-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bhmct_cet",
              },
               {
                 title: "MAH-B.Design-CET-2024",
                 linkUrl: "https://admitcard.mhexam.com/MAH-B-Des-CET-2024",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_bdesign_cet",
               },
               {
                title: "MAH-M.HMCT Integrated CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-M-HMCT-INT-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mhmctintegrated_cet",
              },
               {
                title: "MAH-M.Planning Integrated CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-M-PLAN-INT-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-BBA-BCA-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2024 (Additional Attempt)",
                linkUrl: "https://admitcard.mhexam.com/MAH-BBA-BCA-R-CET-2024/",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                 title: "MAH-MBA/MMS-CET 2024",
                 linkUrl: "https://admitcard.mhexam.com/MAH-MBA-CET-2024",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_mba_mms_cet",
              },
              {
                title: "MAH-M.Arch-CET 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-M-Arch-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-M-HMCT-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_mhmct_cet",
              },
              {
                 title: "MAH-MCA-CET 2024",
                 linkUrl: "https://admitcard.mhexam.com/MAH-MCA-CET-2024",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_mca_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
              // {
              //   title: "MHT-CET-2024",
              //   linkUrl: "",
              //   linkText: "Get Hall Ticket",
              //   alternativeText: "",
              //   enum: "mah_cet_agriculture",
              // },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MAH-LLB (5-Years) CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-LLB5-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_llb_5years_cet",
               }, 
               {
                 title: "MAH-B.Ed (General & Special) & B.Ed ELCT-CET-2024",
                 linkUrl: "https://admitcard.mhexam.com/MAH-B-Ed-CET-2024",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_bed_general_bed_elct_cet",
               },
               {
                 title: "MAH-B.P.Ed.-CET-2024",
                 linkUrl: "https://admitcard.mhexam.com/MAH-B-P-Ed-CET-2024",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_bped_cet",
               },
               {
                 title: "MAH-L.L.B.3 Yrs. CET-2024",
                 linkUrl: "https://admitcard.mhexam.com/MAH-LLB3-CET-2024",
                 linkText: "Get Hall Ticket",
                 alternativeText: "",
                 enum: "mah_llb_3years_cet",
               },
              {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2024",
                linkUrl:
                  "https://admitcard.mhexam.com/MAH-BA-BSC-B-Ed-CET-2024",
                  linkText: "Get Hall Ticket",
                  alternativeText: "",
                enum: "mah_ba_bsc_bed_4years",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-B-Ed-M-Ed-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "MAH-M.Ed CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-M-Ed-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_med_cet",
              },
              {
                title: "MAH-M.P.Ed. CET",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                linkUrl: "https://admitcard.mhexam.com/MAH-M-P-Ed-CET-2024",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MH-Nursing CET 2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-B-Nursing-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "bsc_nursing_cet",
              },
              {
                title: "MH-DPN/PHN-CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-DPN-PHN-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_dpnphn_cet",
              },
              {
                title: "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2024",
                linkUrl: "https://admitcard.mhexam.com/MAH-PGP-CET-2024/",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              // {
              //   title:
              //     "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2024",
              //   linkUrl: "https://admitcard.mhexam.com/MAH-PG-CET-2024",
              //   linkText: "Get Hall Ticket",
              //   alternativeText: "",
              //   enum: "mah_pgp_pgo_msc",
              // },
            ],
          },
        ],
      },
      {
        title: "Fine Art",
        types: [
          {
            type: "",
            linkUrls: [
              {
                title: "MAH-AAC CET",
                linkUrl: "https://admitcard.mhexam.com/MAH-AAC-CET-2024",
                linkText: "Get Hall Ticket",
                alternativeText: "",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/objection_tracking.svg`,
    title: "Objection Tracker",
    linkText: "Track Here",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              /*{
                title: "MHT-CET (PCB) 2024",
                linkUrl: "https://ot.mhexam.com/PCB24",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCM) 2024",
                linkUrl: "https://ot.mhexam.com/MHT24PCM",
                linkText: "Raise an Objection",
                enum: "mht_cet",
              },*/
              {
                title: "MHT-CET (PCM) 2024",
                linkUrl: "https://ot.mhexam.com/PCMAns",
                linkText: "View Response Sheet",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCB) 2024",
                linkUrl: "https://ot.mhexam.com/PCBAns",
                linkText: "View Response Sheet",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT-CET 2024",
                linkUrl: "https://ot.mhexam.com/BHMCT37",
                linkText: "Raise an Objection",
                enum: "mah_bhmct_cet",
              },
              {
                title: "MAH-B.Design-CET-2024",
                linkUrl: "https://ot.mhexam.com/B-Des09",
                linkText: "Raise an Objection",
                enum: "mah_bdesign_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2024",
                linkUrl: "https://ot.mhexam.com/BBA-BCA06",
                linkText: "Raise an Objection",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH-M.Planning Integrated CET-2024",
                linkUrl: "https://ot.mhexam.com/MPLAN52",
                linkText: "Raise an Objection",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2024 (Additional Attempt)",
                linkUrl: "https://ot.mhexam.com/bba-bca95",
                linkText: "Raise an Objection",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
               {
                title: "MAH-MBA/MMS-CET 2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-MBA-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_mba_mms_cet",
              }, 
              {
                title: "MAH-M.Arch-CET 2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-M-Arch-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-M-HMCT-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_mhmct_cet",
              },
               {
                title: "MAH-MCA-CET 2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-MCA-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_mca_cet",
              },
              {
                title: "MAH-M.HMCT Integrated CET-2024",
                linkUrl: "https://ot.mhexam.com/MHMCT83",
                linkText: "",
                alternativeText: "Raise an Objection",
                enum: "mah_mhmctintegrated_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MAH-LLB (5-Years) CET-2024",
                linkUrl: "https://ot.mhexam.com/LLB53",
                linkText: "Raise an Objection",
                enum: "mah_llb_5years_cet",
              },
              {
                title: "MAH-B.Ed (General & Special)",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-B-Ed-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.Ed ELCT-CET-2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-B-Ed-ELCT-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_bed_general_bed_elct_cet",
              }, 
              {
                title: "MAH-B.P.Ed.-CET-2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-B-P-Ed-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_bped_cet",
              },
               {
                title: "MAH-L.L.B.3 Yrs. CET-2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-LLB3-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_llb_3years_cet",
              },
             {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2024",
                linkUrl: "https://ot.mhexam.com/BA-BSC-BED",
                linkText: "Raise an Objection",
                enum: "mah_ba_bsc_bed_4years",
              }, 
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-B-Ed-M-Ed-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "MAH-M.Ed CET-2024",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-M-Ed-CET-2024",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                enum: "mah_med_cet",
              },
              {
                title: "MAH-M.P.Ed. CET",
                linkText: "",
                alternativeText: "Objection Tracking Closed.",
                linkUrl: "https://assessment.mhexam.com/OT/MAH-M-P-Ed-CET-2024",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
              {
                title: "MH-Nursing CET 2024",
                linkUrl: "https://ot.mhexam.com/Nursing02",
                linkText: "Raise an Objection",
                enum: "bsc_nursing_cet",
              },
              {
                title: "MH-DPN/PHN-CET-2024",
                linkUrl: "https://ot.mhexam.com/DPN-PHN81",
                linkText: "Raise an Objection",
                enum: "mah_dpnphn_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title:
                  "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2024",
                linkUrl: "https://ot.mhexam.com/PGP92",
                linkText: "Raise an Objection",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
        ],
      },
      {
        title: "Fine Arts",
        types: [
          {
            type: "",
            linkUrls: [
              /* {
                title: "MAH-AAC CET",
                linkUrl: "https://ot.mhexam.com/Fine-Arts",
                linkText: "Raise an Objection",
                enum: "mah_aac_cet",
              }, */
              {
                title: "MAH-AAC CET",
                linkUrl: "https://ot.mhexam.com/Fine-Arts-R/",
                linkText: "View Response Sheet",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
              /* {
                title: "MHT-CET-2024",
                linkUrl: "",
                linkText: "Raise an Objection",
                enum: "mah_cet_agriculture",
              }, */
            ],
          },
        ],
      },
    ],
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/score_card.svg`,
    title: "Score Card",
    linkText: "Get Score Card",
    openModal: true,
    moreLinks: [
      {
        title: "Technical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
             /* {
                title: "MHT-CET 2024",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mht_cet",
              }, */
              {
                title: "MHT-CET (PCB) 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-PCB-41-CET-2024",
                linkText: "Get Score Card",
                enum: "mht_cet",
              },
              {
                title: "MHT-CET (PCM) 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-PCM-82-CET-2024",
                linkText: "Get Score Card",
                enum: "mht_cet",
              },
              {
                title: "MAH-B.HMCT-CET 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-HMCT-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bhmct_cet",
              },
              {
                title: "MAH-B.Design-CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-Des-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bdesign_cet",
              },
              {
                title: "MAH-M.HMCT Integrated CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-M-HMCT-INT-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_mhmctintegrated_cet",
              },
              {
                title: "MAH-M.Planning Integrated CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-M-PLAN-INT-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_mplanning_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM CET 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-BBA-BCA-INT-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bbabca_cet",
              },
              {
                title: "MAH - B.BCA/BBA/BMS/BBM/ MBA(Int)/MCA(Int) CET 2024 (Additional Attempt)",
                linkUrl: "https://scorecard.mhexam.com/MAH-BBA-BCA-R-CET-2024/",
                linkText: "Get Score Card",
                enum: "mah_bbabca_reattempt_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-MBA/MMS-CET 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-MBA-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_mba_mms_cet",
              }, 
              {
                title: "MAH-M.Arch-CET 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-M-Arch-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_march_cet",
              },
              {
                title: "MAH-M.HMCT-CET 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-M-HMCT-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_mhmct_cet",
              },
              {
                title: "MAH-MCA-CET 2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-MCA-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_mca_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Higher Education",
        types: [
          {
            type: "UG",
            linkUrls: [
             {
                title: "MAH-LLB (5-Years) CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-LLB5-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_llb_5years_cet",
              },
              {
                title: "MAH-B.Ed. (General)",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-Ed-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.Ed & B.Ed ELCT",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-Ed-ELCT-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bed_general_bed_elct_cet",
              },
              {
                title: "MAH-B.P.Ed.-CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-P-Ed-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bped_cet",
              },
              {
                title: "MAH-L.L.B.3 Yrs. CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-LLB3-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_llb_3years_cet",
              },
              {
                title: "MAH-B.A/B.Sc-B.Ed (4 Year Integrated course)-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-BA-BSC-B-Ed-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_ba_bsc_bed_4years",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
              {
                title: "MAH-B.Ed.-M.Ed. (3-Year Integrated Course) – CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-Ed-M-Ed-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_bed_med_3years_cet",
              },
              {
                title: "MAH-M.Ed CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-M-Ed-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_med_cet",
              },
              {
                title: "MAH-M.P.Ed. CET",
                linkText: "Get Score Card",
                linkUrl: "https://scorecard.mhexam.com/MAH-M-P-Ed-CET-2024",
                enum: "mah_mped_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Medical Education",
        types: [
          {
            type: "UG",
            linkUrls: [
             {
                title: "MH-Nursing CET 2024 (For B.Sc Nursing, ANM & GNM courses)",
                linkUrl: "https://scorecard.mhexam.com/MAH-B-Nursing-CET-2024",
                linkText: "Get Score Card",
                enum: "bsc_nursing_cet",
              },
              {
                title: "MH-DPN/PHN-CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-DPN-PHN-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_dpnphn_cet",
              },
            ],
          },
          {
            type: "PG",
            linkUrls: [
             {
                title:
                  "MAH-PGP-CET / PGO-CET / M.Sc(A& SLP)-CET / M.Sc(P & O)-CET-2024",
                linkUrl: "https://scorecard.mhexam.com/MAH-DMER-CET-2024/",
                linkText: "Get Score Card",
                enum: "mah_pgp_pgo_msc",
              },
            ],
          },
        ],
      },
      {
        title: "Fine Arts",
        types: [
          {
            type: "",
            linkUrls: [
             {
                title: "MAH-AAC CET",
                linkUrl: "https://scorecard.mhexam.com/MAH-AAC-CET-2024",
                linkText: "Get Score Card",
                enum: "mah_aac_cet",
              },
            ],
          },
        ],
      },
      {
        title: "Agriculture Education",
        types: [
          {
            type: "",
            linkUrls: [
             /* {
                title: "MHT-CET-2024",
                linkUrl: "",
                linkText: "Get Score Card",
                enum: "mah_cet_agriculture",
              }, */
            ],
          },
        ],
      },
    ],
  }, 
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/admin_card.svg`,
    title: "ARA",
    linkText: "Get Details",
    linkUrl: "",
  },
  {
    icon: `${process.env.PUBLIC_URL}/assets/images/icons/notification.svg`,
    title: "Notifications",
    linkText: "Get Details",
    linkUrl: "https://cetcell.mahacet.org/notices/",
  },
];
