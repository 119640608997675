import React, { useState, useEffect } from "react";
import styles from "../../styles/iconcard.module.scss";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Button, Modal, Row, Col } from "react-bootstrap";
import EditInfo from "../../Pages/User/EditInfo";
import { GetUserData } from "../../axios/apis/Users";

const IconCard = ({ cardData, course }) => {
  const [show, setShow] = useState(false);
  const { icon, title, linkText, linkUrl, openModal, moreLinks, editModal } =
    cardData;
  const [modalLinks, setModalLinks] = useState(moreLinks);

  const [isEditModalVisible, setEditModalVisibility] = useState(false);
  const [userData, setUserData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (title !== "Registration" && moreLinks) {
      const data = moreLinks.map((item) => ({
        title: item.title,
        types: item.types.map((type) => ({
          type: type.type,
          linkUrls: type.linkUrls.filter((innerItem) => {
            const userCourseIndex = course.findIndex(
              (userCourse) =>
                userCourse.name === innerItem.enum &&
                userCourse.status === "Completed"
            );
            return !(userCourseIndex === -1);
          }),
        })),
      }));
      setModalLinks(data);
    } else if (title === "Registration" && moreLinks) {
      const data = moreLinks.map((item) => ({
        title: item.title,
        types: item.types.map((type) => ({
          type: type.type,
          linkUrls: type.linkUrls.map((innerItem) => {
            const userCourseIndex = course.findIndex(
              (userCourse) => userCourse.name === innerItem.enum
            );
            if (userCourseIndex !== -1) {
              innerItem.linkText =
                course[userCourseIndex].status === "Completed"
                  ? "Enrolled"
                  : `Status (${course[userCourseIndex].status})`;
              innerItem.linkStyle =
                course[userCourseIndex].status === "Completed"
                  ? "text-success"
                  : "text-warning";
            }
            return innerItem;
          }),
        })),
      }));
      setModalLinks(data);
    }
  }, [course, moreLinks, title]);

  const handleButtonClick = () => {
    GetUserData()
      .then((result) => {
        setUserData(result);
        setEditModalVisibility(true);
      })
      .catch((error) => {
        if (error.status === 404) {
        }
      });
  };
  const handleCloseModal = () => {
    setEditModalVisibility(false);
  };

  return (
    <>
      <div
        className={`p-3 p-md-4 rounded-4 border border-light shadow d-flex gap-3 gap-md-4 align-items-center h-100 position-relative ${
          styles.cet_icon_card
        } ${
          (linkUrl === "" || linkUrl === null) && `pe-none ${styles.disabled}`
        }`}
      >
        <ReactSVG src={icon} className={`${styles.cet_icon_wrap}`} />
        <div>
          <h3 className="h6 mb-1">{title}</h3>
          {openModal ? (
            <>
              <Link
                onClick={handleShow}
                className="fs-7 fw-semibold d-inline-flex align-items-center gap-2 stretched-link"
              >
                {linkText}{" "}
                <ReactSVG
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/right_arrow.svg`}
                />
              </Link>
            </>
          ) : (
            <Link
              to={linkUrl}
              target={title === "Help Desk" ? "_self" : "_blank"}
              className="fs-7 fw-semibold d-inline-flex align-items-center gap-2 stretched-link"
            >
              {editModal !== true ? linkText : ""}{" "}
              {editModal !== true && (
                <ReactSVG
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/right_arrow.svg`}
                />
              )}
            </Link>
          )}

          {editModal && (
            <>
              <Link
                onClick={handleButtonClick}
                className="fs-7 fw-semibold d-inline-flex align-items-center gap-2 stretched-link"
              >
                {linkText}{" "}
                <ReactSVG
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/right_arrow.svg`}
                />
              </Link>
            </>
          )}
        </div>
      </div>

      {openModal && (
        <>
          {/* modal start */}
          <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            scrollable
            backdrop="static"
          >
            <Modal.Body className="px-md-4 pb-md-4 px-lg-5 pb-lg-5 px-xl-6 pb-xl-6 pt-0">
              <div className="cet_modal_close_wrap text-end mx-n3 mx-md-n4 mx-lg-n5 mx-xl-n6 bg-white z-1">
                <Button
                  variant="danger"
                  onClick={handleClose}
                  className="cet_modal_close"
                >
                  <ReactSVG
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/close.svg`}
                  />
                </Button>
              </div>
              <div className="d-flex flex-column row-gap-6">
                {modalLinks?.map((item, index) => (
                  <>
                    <div key={index}>
                      <h3 className="h5 text-primary">{item.title}</h3>
                      {item.types.map((innerItem, innerIndex) => (
                        <>
                          <h6 className="h6 text-primary course">
                            {innerItem.type}
                          </h6>
                          {innerItem.linkUrls.length ? (
                            <Row className="row-gap-3">
                              {innerItem.linkUrls.map((course, innerIndex) => (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={4}
                                  xl={3}
                                  key={innerIndex}
                                >
                                  <div
                                    className={`p-2 p-md-3 rounded-4 border border-light shadow d-flex flex-column gap-2 h-100 position-relative ${
                                      styles.cet_icon_card
                                    } ${
                                      (course.linkUrl === "" ||
                                        course.linkUrl === null) &&
                                      `pe-none ${styles.disabled}`
                                    }`}
                                  >
                                    <h4 className="h6 mb-0">{course.title}</h4>
                                    <Link
                                      to={course.linkUrl}
                                      target="_blank"
                                      className={`fs-7 fw-semibold d-inline-flex align-items-center gap-2 stretched-link ${
                                        course.linkStyle || ""
                                      }`}
                                    >
                                      {course.linkText && (
                                        <>
                                          {course.linkText}{" "}
                                          <ReactSVG
                                            src={`${process.env.PUBLIC_URL}/assets/images/icons/right_arrow.svg`}
                                          />
                                        </>
                                      )}
                                      {!course.linkText && !['Registration Closed.','Objection Tracking Closed.'].includes(course.alternativeText) && (
                                        <>
                                          {course.alternativeText}{" "}
                                        </>
                                      )}
                                    </Link>
                                    {['Registration Closed.','Objection Tracking Closed.'].includes(course.alternativeText) && (
                                        <span className={`fs-7 fw-semibold text-danger`}>
                                          {course.alternativeText}{" "}
                                        </span>
                                    )}
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <div className="p-2 p-md-3 bg-lighter rounded-4 border border-light d-flex align-items-center column-gap-3">
                              <ReactSVG
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/not_found.svg`}
                                beforeInjection={(svg) => {
                                  svg.classList.add("text-primary");
                                  svg.setAttribute(
                                    "style",
                                    "width: 24px; height: 24px"
                                  );
                                }}
                              />
                              <p className="fs-7 mb-0 fw-semibold text-primary">
                                No Enrollment Found
                              </p>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </Modal.Body>
          </Modal>
          {/* modal end */}
        </>
      )}

      {isEditModalVisible && (
        <EditInfo
          userData={userData}
          showEditModal={isEditModalVisible}
          onHide={handleCloseModal}
        ></EditInfo>
      )}
    </>
  );
};

export default IconCard;
