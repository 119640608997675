import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Error from './Pages/Error';
import keycloakService from "./modules"; 

const AppRouter = () => {
  const Component = keycloakService.getIsAuthenticated() ? Home : Error;
  return (
    <Routes>
      <Route path="/" element={<Component />} />
    </Routes>
  );
};

export default AppRouter;
