import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppProvider } from './contexts/AppContext';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/global.scss';

import reportWebVitals from './reportWebVitals';
import keycloakService from './modules';

const renderApp = () => ReactDOM.createRoot(document.getElementById("root")).render(
<React.StrictMode>
  <AppProvider>
    <Router>
        <App />
      </Router>
      </AppProvider>
  </React.StrictMode>
);
keycloakService.initKeycloak(renderApp);

reportWebVitals();
